import { ProductKind } from '../model/product/response/product';
import {
  InventoryTargetType,
  PriceType,
  LevelOfDifficulty,
  FripKind,
} from '../model/product/enum';

export const kindOptions = [
  {
    value: ProductKind.OFFLINE,
    text: '오프라인',
    standardCategoryId: '',
  },
  {
    value: ProductKind.ONLINE,
    text: '온라인',
    standardCategoryId: '',
  },
];

export const fripKindOptions = [
  {
    value: FripKind.MEETUP,
    text: '번개프립',
    standardCategoryId: '',
  },
];

export const difficultyOptions = [
  {
    value: LevelOfDifficulty.NONE,
    text: '없음',
  },
  {
    value: LevelOfDifficulty.VERY_EASY,
    text: '매우쉬움',
  },
  {
    value: LevelOfDifficulty.EASY,
    text: '쉬움',
  },
  {
    value: LevelOfDifficulty.NORMAL,
    text: '보통',
  },
  {
    value: LevelOfDifficulty.HIGH,
    text: '어려움',
  },
];

export const categorySearchOptions = [
  {
    value: 'select',
    text: '카테고리 직접 선택',
  },
  {
    value: 'search',
    text: '카테고리 검색',
  },
];

export const saleStartOptions = [
  { value: 'now', text: '검수 완료 후 즉시 판매 시작' },
  { value: 'manual', text: '직접 지정한 날부터 판매 시작' },
];

/**
 * 판매 유형
 */
export const planTypeOptions = [
  {
    value: false,
    text: '날짜 조율형',
    description:
      '호스트님이 크루 연락처로 별도 연락하여 일정을 조율하는 형태의 프립입니다. (에스테틱, 네일 등에 적합)',
  },
  {
    value: true,
    text: '날짜 지정형',
    description:
      '호스트님께서 날짜와 옵션을 등록하여 가능한 날에만 예약을 받을 수 있는 프립입니다. 일정 관리 및 고객 관리를 더 간편하게 하실 수 있습니다. (에스테틱, 네일 제외한 대부분의 프립)',
  },
];
/**
 * 상품 유형 = 배송
 */
export const goodsTypeOptions = [
  {
    value: false,
    text: '배송 상품',
    description: '배송상품 입니다.',
  },
];
/**
 * 상품 유형 = 온라인이고 1차 카테고리 = VOD 또는 전자책인 경우
 * prefix: 00021092(VOD)
 * prefix: 00021059(전자책)
 * 판매유형은 옵션만 등록으로 판단
 * 예약 status가 없음
 */
export const onlineSaleTypeOptions = [
  {
    value: false,
    text: '디지털컨텐츠',
    description:
      'VOD, 전자책과 같은 디지털컨텐츠 상품의 경우 크루가 결제하는 즉시 취소가 불가합니다.',
  },
];

export const purchaseLimitTargetTypeOptions = [
  {
    text: '상품별',
    value: 'BY_PRODUCT',
  },
  {
    text: '옵션별',
    value: 'BY_ITEM',
  },
];

export const purchaseLimitOptions = [
  {
    text: '1인 1회',
    value: 'ONCE',
  },
  {
    text: '기간 제한',
    value: 'TERM',
  },
];

export const inventoryTargetTypeOptions = [
  {
    value: InventoryTargetType.BY_SCHEDULE,
    text: '일정별 모집인원 제한',
    description:
      '선택한 옵션과 무관하게 일정별로 모집할 전체 인원을 제한합니다.',
  },
  {
    value: InventoryTargetType.BY_ITEM, // 옵션별이지만 사실은 아이템별 모집인원 제한
    text: '옵션별 모집인원 제한',
    description:
      '성별에 따라 모집인원을 제한하는 등 특정 조건 또는 옵션별로 제한합니다.',
  },
];

export const priceTypeOptions = [
  {
    value: PriceType.ALL,
    text: '전체',
  },
  {
    value: PriceType.RETAIL,
    text: '정가',
  },
  {
    value: PriceType.SALE,
    text: '판매가',
  },
];
