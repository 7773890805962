import { ValidationResult } from '@/common/model/ValidationResult';
import { ValidationService } from '@/common/service/ValidationService';
import { ProductKind } from '../model/product/enum';
import { ProductParam } from '../model/product/param/productParam';

export class ProductParamValidateService extends ValidationService {
  public validateBasicInfo(
    form: ProductParam,
    hostId: string
  ): ValidationResult {
    let isValid = true;
    const alert = {
      title: '',
      html: '',
      message: '',
    };

    const invalidParamNames = [];
    if (hostId === '') {
      isValid = false;
      invalidParamNames.push('호스트');
    }
    if (form.kind === ProductKind.NONE) {
      isValid = false;
      invalidParamNames.push('프립유형');
    }
    if (form.standardCategoryId === '') {
      isValid = false;
      invalidParamNames.push('카테고리');
    }
    if (form.attributeIds.length === 0) {
      isValid = false;
      invalidParamNames.push('프립속성');
    }
    if (form.title === '') {
      isValid = false;
      invalidParamNames.push('프립명');
    }
    if (
      form.kind === ProductKind.OFFLINE &&
      form.frip?.locationsOfVenue.length === 0
    ) {
      isValid = false;
      invalidParamNames.push('진행장소');
    }

    if (!isValid) {
      alert.title = `기본 정보를 입력해주세요.`;
      alert.html = this.makeUlHtml(invalidParamNames);
      alert.message = '선택 값에 따라 입력해야 하는 항목이 달라집니다.';
    }
    return {
      isValid: isValid,
      alert: alert,
    };
  }
  public validateParamForCreate(
    form: ProductParam,
    hostId: string
  ): ValidationResult {
    let isValid = true;
    const alert = {
      title: '',
      message: '',
      html: '',
      type: 'primary',
    };
    const invalidFieldNames = [];
    if (hostId === '') {
      isValid = false;
      invalidFieldNames.push('호스트 정보');
    }
    if (form.kind === ProductKind.NONE) {
      isValid = false;
      invalidFieldNames.push('프립유형');
    }
    if (form.attributeIds.length === 0) {
      isValid = false;
      invalidFieldNames.push('프립속성');
    }
    if (form.title === '') {
      isValid = false;
      invalidFieldNames.push('프립명');
    }

    if (!isValid) {
      alert.title = `임시 저장을 위한 최소한의 정보는 반드시 입력해주세요`;
      alert.html = this.makeUlHtml(invalidFieldNames);
      alert.message = '선택 값에 따라 입력해야 하는 항목이 달라집니다.';
    }
    return {
      isValid: isValid,
      alert: alert,
    };
  }
}
